/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Map } from 'immutable'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, fetchMany, notifyUser } from '../actions'
import { CONFIG, MINUSER, CONFIGS, CACHE, MODEL, PREFERENCES, SELECTED } from '../selectors'
import ModelSelectSidebar from '../components/ModelSelectSidebar'


function mapStateToProps(state, ownProps) {
  const cache = CACHE(state)
  const preferences = PREFERENCES(state, ownProps.modelname)
  let meta_cache = Map({ settings: {} })
  meta_cache = meta_cache.set('settings', cache.get('settings'))
  return {
    user: MINUSER(state),
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache,
    meta_cache,
    config: CONFIG(state, ownProps.modelname),
    configs: CONFIGS(state),
    model: MODEL(state, ownProps.modelname),
    selected: SELECTED(state, ownProps.modelname),
    tableconfig: Map({ column_preferences: preferences ? preferences : Map({}) })
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    fetchMany,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(ModelSelectSidebar))
