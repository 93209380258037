import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import isEqual from 'react-fast-compare'

import TableManager from '../../../containers/TableManager'
import { capitalize, isConditional, logEvent, updateSearchParms } from '../../../utils'
import { Button } from '../../ui/Button'
import InlineSelect from '../forms/inputs/InlineSelect'
import AsyncInlineSelect from '../forms/inputs/AsyncInlineSelect'
import Step from '../Step'
import { useBreakPoint } from '../../../hooks/useBreakPoint'
import PageJump from './PageJump'


const Meta = props => {
  const [ statusname, setStatusname ] = useState('')
  const [ status, setStatus ] = useState({})
  const [ statuses, setStatuses ] = useState([])
  const [ domainname, setDomainname ] = useState('')
  const [ domain, setDomain ] = useState({})
  const [ domains, setDomains ] = useState([])
  const [ domainfield, setDomainfield ] = useState(null)
  const [ limit, setLimit ] = useState(20)
  const desktop = useBreakPoint()
  const [ prevprops, setPrevprops ] = useState(props)

  const stepPage = dir => { // Step through result pages
    const { count, params } = props.model
    const { limit: newlimit } = params
    const url = props.model[dir]
    let offset = 0
    switch (dir) {
      case 'next':
      case 'previous':
        if (url && url.match(/offset=([^&]*)/)) { offset = url.match(/offset=([^&]*)/)[1] }
        break
      case 'last':
        offset = Math.floor(count / newlimit) * newlimit
        // fix offset when there's no remainder
        if (offset === count) { offset -= newlimit }
        break
      default:
        offset = 0
    }
    if (props.query) {
      props.query({ offset, limit })
    } else {
      updateSearchParms('offset', offset)
    }
    logEvent('PAGE_STEP', { modelname: props.config.modelname, offset, limit: newlimit })
  }

  const initStatuses = () => { // Below sets the initial status options for the model
    let newstatuses = [ { value: '', label: 'All' } ]
    const field = props.findStatus()
    if (!field) { return }
    const fieldname = `${field.name}__in`
    const form = {}
    form[fieldname] = field.defaultValue
    if (field && field.options) { newstatuses = [ ...newstatuses, ...field.options.filter(o => isConditional(o, 'show', true, form, props.user, props.cache)) ] } // Include All
    newstatuses = newstatuses.map(s => {
      if (s.value === true) { return { ...s, value: 1 } }
      if (s.value === false) { return { ...s, value: 0 } }
      return { ...s }
    })
    let newstatus = newstatuses.find(o => (
      props.params[fieldname] && o.value.toString() === props.params[fieldname].toString()
    ))
    if (!newstatus) { newstatus = newstatuses.find(o => o.value === '') }
    setStatusname(fieldname)
    setStatus(newstatus)
    setStatuses(newstatuses)
  }

  const initDomains = () => { // Below sets the initial status options for the model
    let newdomains = [ { value: '', label: 'All', id: '', domain: 'All' } ]
    const field = props.findDomain()
    if (!field) { return }
    const fieldname = `${field.name}__in`
    const form = {}
    form[fieldname] = field.defaultValue
    if (field && field.options) { newdomains = [ ...newdomains, ...field.options.filter(o => isConditional(o, 'show', true, form, props.user, props.cache)) ] } // Include All
    let newdomain = newdomains.find(o => (
      props.params[fieldname] && o.value.toString() === props.params[fieldname].toString()
    ))
    if (!newdomain) { newdomain = newdomains.find(o => o.value === '') }
    setDomainname(fieldname)
    setDomain(newdomain)
    setDomains(newdomains)
    setDomainfield(field)
  }

  const changeStatus = e => { // Change the status filter on the page model
    setStatus(e)
    const field = props.findStatus()
    updateSearchParms(`${field.name}__in`, e.value)
    logEvent('SELECT_STATUS', { modelname: props.config.modelname, status: e.value })
  }

  const changeDomain = e => { // Change the status filter on the page model
    if ([ null, undefined ].includes(e.value)) {
      return
    }
    setDomain(e)
    const field = props.findDomain()
    updateSearchParms(`${field.name}__in`, e.value)
    logEvent('SELECT_DOMAIN', { modelname: props.config.modelname, domain: e })
  }

  const changelimit = e => {
    const size = e.value
    if (props.query) {
      props.query({ limit: size })
    } else {
      sessionStorage.setItem('limit', size)
      updateSearchParms('limit', size)
    }
    logEvent('CHANGE_PAGE_SIZE', { modelname: props.config.modelname, pagesize: size })
  }

  const calculateRecords = () => {
    let from = 0
    let to = 0
    if (props.params) {
      const { offset, limit: newlimit } = props.params
      const count = Number(props.count)
      from = Number(offset) + 1 || 1
      to = (from - 1 + limit) > count ? count : from - 1 + newlimit
    }
    return { from, to }
  }

  useEffect(() => {
    const newlimit = sessionStorage.getItem('limit')
    if (limit) { setLimit(newlimit) }
    initStatuses()
    initDomains()
  }, [])

  useEffect(() => {
    if (prevprops.config.modelname !== props.config.modelname) {
      initStatuses()
      initDomains()
    } // Model change
    if (prevprops.params !== props.params) { // Params have changed
      const field = props.findStatus()
      if (field) {
        const fieldname = `${field.name}__in`
        if (props.params[fieldname] !== status.value) { // Check for status variance ie. on page nav backwards
          let newstatus = statuses.find(o => typeof props.params[fieldname] !== 'undefined' && o.value.toString() === props.params[fieldname].toString())
          if (typeof props.params[fieldname] === 'undefined') { newstatus = statuses.find(o => o.value === '') }
          if (newstatus && status !== newstatus) { setStatus(newstatus) }
        }
      }
      const newdomainfield = props.findDomain()
      if (newdomainfield) {
        const fieldname = `${newdomainfield.name}__in`
        if (props.params[fieldname] !== domain.value) { // Check for status variance ie. on page nav backwards
          let newdomain = domains.find(o => typeof props.params[fieldname] !== 'undefined' && o.value.toString() === props.params[fieldname].toString())
          if (typeof props.params[fieldname] === 'undefined') { newdomain = domains.find(o => o.value === '') }
          if (newdomain && !isEqual(domain, newdomain)) {
            setDomain(newdomain)
            setDomainfield(newdomainfield)
          }
        }
      }
    }
    setPrevprops(props)
  }, [ props ])

  const records = calculateRecords()
  const { selectedRecords } = props
  const strlimit = limit ? limit.toString() : 20

  let status_label
  switch (props.config.modelname) {
    case 'residential':
    case 'commercial':
    case 'holiday':
    case 'projects':
      status_label = 'Listing Status'
      break
    case 'deals':
      status_label = 'Deal Stage'
      break
    default:
      status_label = `${capitalize(props.config.singular)} Status`
  }
  status_label = <div className="input-group-addon">{status_label}:</div>

  return (
    <div className="tablemeta">
      {statusname && !props.advanced &&
        <InlineSelect
          id={`field-${statusname}`}
          name={statusname}
          prefix={status_label}
          className="inline-select meta-status"
          classNamePrefix="inline"
          defaultValue={status}
          autosize={true}
          onChange={changeStatus}
          options={statuses}
        />
      }
      {
        selectedRecords && selectedRecords.length ? <div className="selected-records"><span>{`${selectedRecords.length} ${selectedRecords.length > 1 ? 'Records' : 'Record'} Selected`}</span></div> : ''
      }
      {domainname && !props.advanced &&
        <AsyncInlineSelect
          id={`field-${domainname}`}
          name={domainname}
          prefix={<div className="input-group-addon">Domain</div>}
          className="inline-select meta-status"
          classNamePrefix="inline"
          fetchMany={props.fetchMany}
          defaultValue={domain}
          modelname="domains"
          autosize={true}
          onChange={changeDomain}
          optionlabel={domainfield.optionlabel}
          noclear
          form={{}}
          field={{
            value: domain.value
          }}
          options={domains}
        />
      }

      <div className="page-tools">
        <PageJump
          params={props.params}
          endpoint={props.config.endpoint}
          modelname={props.config.modelname}
          count={props.count}
        />

        <div className="page-buttons">
          <Step
            stepPage={stepPage}
            next={props.next}
            previous={props.previous}
          />

          {desktop && <div className="record-count">
            {records.to ? records.from : 0} - {records.to} of {props.count}
          </div> }
          {desktop && <InlineSelect
            id="field-limit"
            name="limit"
            className="inline-select meta-limit"
            classNamePrefix="inline"
            defaultValue={{ value: limit, label: strlimit }}
            selectedValue={(props.params) ? Number(props.params.limit) : ''}
            autosize={true}
            options={[
              { value: 20, label: '20' },
              { value: 50, label: '50' },
              { value: 100, label: '100' }
            ]}
            onChange={e => changelimit(e, props.params)}
          />}

          {props.toggleManager &&
          <div className="table-manager">
            <Button icon="#icon16-Columns" className="btn btn-subtle btn-icon-16 btn-table-manager" onClick={props.toggleManager} />
            <TableManager match={props.match} modelname={props.modelname} />
          </div>
          }
        </div>
      </div>

    </div>
  )
}


Meta.propTypes = {
  advanced: PropTypes.bool,
  config: PropTypes.object,
  match: PropTypes.object,
  count: PropTypes.number,
  modelname: PropTypes.string,
  next: PropTypes.string,
  params: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object.isRequired,
  previous: PropTypes.string,
  toggleManager: PropTypes.func,
  findStatus: PropTypes.func,
  findDomain: PropTypes.func,
  fetchMany: PropTypes.func,
  model: PropTypes.object,
  selectedRecords: PropTypes.array,
  query: PropTypes.func
}

export default Meta
