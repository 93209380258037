/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import { Formik, getIn } from 'formik'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import config from '../../config/deal-financial-structure.json'
import { SETTINGS } from '../../selectors'
import { Button } from '../ui/Button'
import { hasPermission } from '../../utils'
import CustomForm from './forms/CustomForm'
import FieldGroup from './forms/FieldGroup'
import MetaDetail from './MetaDetail'
import Card from './Card'


const FinancialStructure = props => {
  const { model, actions, currency } = props

  const abortController = useRef(new AbortController())
  const [ mounted, setMounted ] = useState(true)
  const [ structure, setStructure ] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [ groups, setGroups ] = useState(Object.keys(config.config.fieldgroups))

  const loadStructure = useCallback(() => {
    new Promise((resolve, reject) => actions.fetchDealFinancialStructure(
      {
        id: model.id,
        signal: abortController.current.signal
      },
      resolve,
      reject
    )).then(r => {
      if (mounted) {
        const total_property_price = model.sale_price
        setStructure({
          ...r,
          total_property_price,
          agent: model.agent,
          selling_agents: model.selling_agents,
          status: model.status
        })
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
    })
  }, [ model.id ])

  useEffect(() => {
    loadStructure()
    return () => {
      abortController.current.abort()
      setMounted(false)
    }
  }, [])

  return (
    <Card
      background={true}
      collapsable={false}
      header={
        <h3>Financial Structure</h3>
      }
      bodyclass="no-top-padding financial-structure"
      body={() => (
        groups.map((group, gidx) => {
          const fields = config.config.fields.filter(field => field.group === group)
          return <div key={`group-${gidx}`} className="meta-group">
            {fields.map((field, fidx) => (
              <MetaDetail
                className={field.cols}
                key={`${group}-${fidx}`}
                label={field.label}
                value={getIn(structure, field.name)}
                format={field.format}
                field={{ ...field, currency }}
              />
            ))}
          </div>
        })
      )}
    />
  )
}

FinancialStructure.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
  form: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  model: PropTypes.object,
  currency: PropTypes.string
}

const mapStateToProps = state => {
  const settings = SETTINGS(state)
  return ({
    currency: settings.get('default_currency')
  })
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(FinancialStructure))
