import merge from 'deepmerge'
import { ErrorMessage, getIn } from 'formik'
import React from 'react'
import Creatable from 'react-select/creatable'
import { reduceGroupedOptions } from 'react-select-async-paginate'
import { components } from 'react-select'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'

import log from '../../../../logging'
import Loader from '../../Loader'
import { composeOptionLabel, hasPermission, handleSubmitError, convertArrayToObject, uniqueArray, overwriteMerge, buildOptionLabel } from '../../../../utils'
import QueryBuilder from '../../QueryBuilder'
import { withResponsiveSelect } from './ResponsiveSelect'
import Label from './Label'


const CustomOption = props => {
  const { head, sub, img, tags } = props.data
  return <components.Option {...props} >
    <div className="customopt">
      {img && <img src={img} alt="" />}
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
      {tags &&
        <div className="select-tags">
          {tags.map((t, idx) => (
            <span className={`select-tag ${t}`} key={`tag-${idx}`}>{t}</span>
          ))}
        </div>
      }
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const ResponsiveAsyncCreatableSelect = withResponsiveSelect(Creatable)

class AsyncCreateSelectInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.defaultReduceOptions = this.defaultReduceOptions.bind(this)
    this.loadData = this.loadData.bind(this)
    this.shouldLoadMore = this.shouldLoadMore.bind(this)
    this.loadIndicator = this.loadIndicator.bind(this)
    this.customOption = this.customOption.bind(this)
    this.createData = this.createData.bind(this)
    this.isValidNewOption = this.isValidNewOption.bind(this)
    const { watch, form, field, multi } = props
    const watchvalues = watch ? watch.map(v => form.values[v]) : []
    let cacheuniqs = [ ...watchvalues ]
    if (multi) {
      cacheuniqs = [ field.value ? field.value.length : 0, ...cacheuniqs ]
    } else {
      cacheuniqs = [ field.value || 0, ...cacheuniqs ]
    }
    this.state = {
      options: props.options ? props.options.map(o => buildOptionLabel(props, o)) : [],
      results: [],
      hasMore: false,
      init: false,
      inputValue: '',
      manual: false,
      cacheuniqs: cacheuniqs,
      reset: false,
      indexedResults: {}
    }
    this.AsyncSelectRef = React.createRef()
    this.mergeOptions = this.mergeOptions.bind(this)
    this.setMetaFieldStatus = this.setMetaFieldStatus.bind(this)
    this.fetch = null
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidUpdate(prevprops, prevState) {
    if (!isEqual(this.state.results, prevState.results) && this._is_mounted) {
      const indexedResults = convertArrayToObject(this.state.results, this.props.optionvalue || 'id')
      this.setState({ indexedResults })
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  handleChange(v) {
    const { multi, form, field, dependents, onchange, modelname } = this.props
    let vals = []
    if (v) { // Is there a value? Used for clearing select
      if (Array.isArray(v)) {
        if (v.length > 0) { // Array value with values
          v.forEach(i => {
            if (Array.isArray(i.value)) {
              vals.push(i.value[0])
            } else {
              vals.push(i.value)
            }
          })
        } else {
          vals = v
        }
      } else if (multi) {
        if (v.value) { vals.push(v.value) }
      } else if (v.value) {
        vals = v.value
      } else if (v !== '') {
        vals = v
      } else {
        vals = null
      }
      form.setFieldValue(field.name, vals).then(() => {
        form.setFieldTouched(field.name)
        this.setMetaFieldStatus(vals)
        const delta = { [modelname]: {} }
        if (vals && !Array.isArray(vals)) {
          vals = [ vals ]
        }
        vals.filter(val => this.state.indexedResults[val]).forEach(val => {
          delta[modelname][val] = this.state.indexedResults[val]
        })
        this.props.actions.cacheDelta(delta)
      })
      if (dependents && !onchange) { // Unset any dependents on a field without a custom change action
        dependents.forEach(dependent => { form.setFieldValue(dependent, null, false) })
      }
    } else { // Clear the select as there is no value
      form.setFieldValue(field.name, null).then(() => {
        this.setMetaFieldStatus(null)
        form.setFieldTouched(field.name)
      })
    }
  }

  customOption(props) {
    if (this.props.labelformat && (props.data.head || props.data.tags)) { return <CustomOption {...props} /> }
    return <components.Option {...props} />
  }

  async loadData(search, prevOptions, additional) {
    const { modelname, labelseparator, labelformat, labelgrouper,
      params, options, optionlabel, optionvalue, form } = this.props

    let defaultOptions = []
    try {
      let queryparams = {}
      if (params) {
        const query = new QueryBuilder(params)
        queryparams = query.getAllArgs()
      }
      if (labelgrouper) { // If the options are grouped, iterate over each group and get the length of the inner options arrays
        queryparams.offset = prevOptions.map(group => group.options.length).reduce((a, b) => a + b, 0) // Get array lengths and reduce them into the final total
      } else {
        queryparams.offset = additional.offset
        if (queryparams.offset === 0 || queryparams.offset < 20) { delete queryparams.offset }
      }
      if (!queryparams.offset && options) {
        defaultOptions = options.map(o => buildOptionLabel(this.props, o))
      }
      const values = {
        formmodel: form.initialValues.modelname,
        modelname,
        labelseparator,
        labelformat,
        labelgrouper,
        optionlabel,
        optionvalue,
        term: search,
        conflict: true,
        params: queryparams,
        signal: this.AbortController.signal
      }
      const response = await new Promise((resolve, reject) =>
        this.props.fetchMany({ values, resolve, reject, noloader: true })).catch(() =>
        ({ options: [], hasMore: false, additional }))
      let more_params = queryparams
      const r = Object.assign({}, response)
      const results = uniqueArray(merge(this.state.results, r.options), 'id')
      r.options = r.options.map(o => buildOptionLabel({
        optionlabel,
        optionvalue,
        labelseparator,
        labelformat,
        labelgrouper
      }, o))
      if (this._is_mounted) {
        this.setState({ init: true })
      }
      if (defaultOptions.length) { // Apply search to static defaults as well
        const searchLower = search ? search.toLowerCase() : null
        defaultOptions = defaultOptions.filter(option => {
          if (searchLower) {
            return option.value.toLowerCase().includes(searchLower)
          }
          return true
        })
        r.options = uniqueArray([ ...defaultOptions, ...r.options ], 'value')
      }
      if (r.hasMore) {
        const new_query = new QueryBuilder(r.hasMore)
        more_params = new_query.getAllArgs()
      }
      if (!isEqual(this.state.results, results) && this._is_mounted) {
        this.setState({ results: uniqueArray(merge(this.state.results, results), 'id') })
      }
      return { ...{ ...r, hasMore: !!r.hasMore }, additional: more_params }
    } catch (e) {
      if (e.error !== 'The operation was aborted. ') {
        log.error(e)
      }
    }
    if (this._is_mounted) {
      this.setState({ init: true })
    }
    return { options: [], hasMore: false, additional }
  }

  setMetaFieldStatus(vals) {
    const { form, statusfield, cache } = this.props
    let meta_vals = null
    if (statusfield) {
      if (Array.isArray(vals)) {
        meta_vals = vals.map(v => getIn(cache, `${v}.${statusfield.field}`, [])).filter(v => (Array.isArray(v) ? v.length : v))
        if (meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = { ...form.status }
        meta[statusfield] = meta_vals
        form.setStatus(meta)
      } else if (vals) {
        meta_vals = cache[vals] ? cache[vals][statusfield.field] : []
        if (meta_vals && meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = { ...form.status }
        meta[statusfield.key] = meta_vals
        form.setStatus(meta)
      } else {
        const meta = { ...form.status }
        meta[statusfield.key] = null
        form.setStatus(meta)
      }
    }
  }

  createData(i) {
    const { modelname, endpoint, optionlabel, multi, cache, field, labelseparator, additional_data } = this.props
    let values = {
      modelname: modelname,
      endpoint: endpoint
    }
    values[optionlabel] = i // Add the option data for related model
    if (additional_data) {
      values = { ...additional_data, ...values }
    }
    return new Promise((resolve, reject) =>
      this.props.createModel({ values, resolve, reject })).then(r => {
      if (multi) {
        let vals = composeOptionLabel(cache, field.value, optionlabel, labelseparator)
        if (!Array.isArray(vals)) {
          vals = [ vals ]
        }
        vals.push({ value: r.id, label: i })
        vals = vals.filter(v => v)
        this.handleChange(vals)
      } else {
        this.handleChange({ value: r.id, label: i })
      }
    }).catch(e => {
      handleSubmitError(e)
    })
  }

  isValidNewOption(inputValue, selectValue, selectOptions) {
    if (this.props.permissions && !hasPermission(this.props.permissions, this.props.user.permissions)) { return false }
    if (inputValue.trim().length === 0 || selectOptions.find(option => option.name === inputValue)) { return false }
    return true
  }

  shouldLoadMore(scrollHeight, clientHeight, scrollTop) {
    const bottomBorder = (scrollHeight - clientHeight) / 2
    return bottomBorder < scrollTop
  }

  defaultReduceOptions(prevOptions, loadedOptions) {
    const options = prevOptions.concat(loadedOptions)
    if (this._is_mounted) {
      this.setState({ options }, () => {
        if (this.props.updateTemplates) {
          this.props.updateTemplates(this.mergeOptions(options))
        }
      })
    }
    return options
  }

  /*
  Function for merging simple options as well as option groups
  */
  mergeOptions(newOptions) {
    const { labelgrouper } = this.props
    if (labelgrouper) {
      const all_options = this.state.options.map(group => {
        const options = newOptions.filter(g => g.label === group.label)
        group.options = merge(this.state.options, options, { arrayMerge: overwriteMerge })
        return group
      })
      return all_options
    }
    return uniqueArray([ ...this.state.options, ...newOptions ], 'value')
  }

  loadIndicator() {
    return <Loader inline />
  }

  render() {
    const { cache, field, form, optionlabel, labelseparator, label, labelformat, multi,
      noclear, labelgrouper, readonly, disabled, placeholder, id, modelname, plural, optionvalue,
      singular, noOptions, closemenuonselect, createOptionPosition } = this.props
    // Generate the current value/s from the cache
    let vals = composeOptionLabel(cache, field.value, optionlabel, labelseparator)
    if (
      (!vals || (Array.isArray(vals) && !vals.length))
      && (Object.keys(this.state.indexedResults).length || (cache && Object.keys(cache).length))
    ) {
      vals = composeOptionLabel({
        ...cache,
        ...this.state.indexedResults
      }, field.value, optionlabel, labelseparator, optionvalue)
    }
    if (!field.name) { return null }
    return (
      <div
        id={id}
        className={`selectinput asyncselectinput form-group ${field.name}`}
        ref={el => { // This may need to be refactored for performance sakes
          if (!el) {return}
          this.el = el
        }
        }>
        <Label htmlFor={field.name}>{label}</Label>
        <div className="forminput">
          <ResponsiveAsyncCreatableSelect
            key={`acs-${field.name}`}
            SelectComponent={Creatable}
            debounceTimeout={300}
            className={'react-select'}
            classNamePrefix="react-select"
            isMulti={multi}
            name={field.name}
            inputId={field.name}
            form={form}
            field={field}
            isClearable={noclear ? false : true}
            isDisabled={readonly || disabled ? true : false}
            loadOptions={this.loadData}
            defaultOptions
            options={this.state.options}
            onChange={this.props.onChange || this.handleChange}
            value={vals}
            labelformat={labelformat}
            cacheUniqs={this.state.cacheuniqs} // toggling this resets the options in the select
            shouldLoadMore={this.shouldLoadMore}
            reduceOptions={labelgrouper ? reduceGroupedOptions : this.defaultReduceOptions}
            placeholder={placeholder}
            noOptionsMessage={ () => {
              if (noOptions) {
                return noOptions
              }
              if (modelname && plural && singular) {
                if ([ 'residential', 'holiday', 'commercial', 'estate', 'projects' ].includes(modelname)) {
                  return `No ${plural} found`
                }
                if (plural.slice(0, plural.length - 1) === singular) { return `No ${plural} found` }
                return `No ${plural} found in this ${singular}`
              }
              return 'No options found'
            }
            }
            components={{
              LoadingIndicator: this.loadIndicator,
              Option: this.customOption
            }}
            additional={{ offset: 0 }}
            onCreateOption={this.createData}
            isValidNewOption={this.isValidNewOption}
            closeMenuOnSelect={closemenuonselect}
            blurInputOnSelect={closemenuonselect}
            onSelectResetsInput={closemenuonselect}
            backspaceRemovesValue={closemenuonselect}
            createOptionPosition={createOptionPosition || 'last'}
          />
          <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
        </div>
      </div>
    )
  }
}

AsyncCreateSelectInput.propTypes = {
  createModel: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  modelname: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  endpoint: PropTypes.object,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  watch: PropTypes.array,
  cache: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  error: PropTypes.object,
  actions: PropTypes.object,
  multi: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  noclear: PropTypes.bool,
  noOptions: PropTypes.string,
  params: PropTypes.string,
  optionlabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  labelformat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  labelgrouper: PropTypes.string,
  labelseparator: PropTypes.string,
  permissions: PropTypes.array,
  optionvalue: PropTypes.string,
  options: PropTypes.array,
  user: PropTypes.object,
  singular: PropTypes.string,
  plural: PropTypes.string,
  onChange: PropTypes.func,
  additional_data: PropTypes.object,
  statusfield: PropTypes.object,
  dependents: PropTypes.array,
  onchange: PropTypes.string,
  updateTemplates: PropTypes.func,
  closemenuonselect: PropTypes.bool,
  createOptionPosition: PropTypes.string
}

export default AsyncCreateSelectInput
